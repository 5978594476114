import { useContext } from 'react'
import get from 'lodash/get'

import {
  calcPrice,
  calcPriceWithFractions,
} from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { interpolatePrice } from 'bl-utils/src/currency/interpolatePrice'

import { CurrencyContext } from '../context/Currency/CurrencyProvider'

export const useAmount = ({
  value,
  format,
  useSymbol,
  currencyOverride,
  displayFractionalPrices,
}: {
  value: number | number[]
  format?: string
  useSymbol?: boolean
  currencyOverride?: string
  displayFractionalPrices?: boolean
}) => {
  const { currency, exchangeRates, isLoading } = useContext(CurrencyContext)

  if (typeof value !== 'number' || isLoading) {
    return ''
  }

  const getDisplayPrice = () => {
    const currencyToUse = currencyOverride || currency
    const priceArr = Array.isArray(value) ? value : [value]

    const newPrices = priceArr.map(current => {
      const price =
        typeof current === 'number' ? current : get(current, 'fields.isk')

      if (exchangeRates && !isLoading) {
        const calculatedPrice = displayFractionalPrices
          ? calcPriceWithFractions(price, exchangeRates[currencyToUse])
          : calcPrice(price, exchangeRates[currencyToUse])
        return formatPrice(calculatedPrice, currencyToUse, useSymbol)
      }

      return formatPrice(price, 'ISK', useSymbol)
    })
    return newPrices
  }

  const price = getDisplayPrice()

  return format ? interpolatePrice(price, format) : price[0]
}
