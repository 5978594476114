import styled, { css } from 'styled-components'

import { InfoIcon } from '../../elements/Icons/InfoIcon'
import { theme } from '../../styles/theme'
import { media, mediaObj } from '../../utils/media'

export const Card = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ theme }) => `${theme.spacing[1]} 0`};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  width: 50%;
  flex-grow: 1;
`

export const ControlButton = styled.button`
  color: var(--color-accent);
  cursor: pointer;
  text-decoration: underline;

  &:disabled {
    opacity: 0.5;
  }

  & + & {
    margin-left: ${({ theme }) => theme.spacing[0.5]};
  }
`

export const Price = styled.div`
  margin-left: auto;
  position: relative;
  align-self: stretch;
  display: flex;
  padding-bottom: 13px;
  flex-shrink: 0;
`
export const ControlWrapper = styled.div`
  bottom: 0;
  display: flex;
  position: absolute;
  right: 0;
`
export const Disclaimer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spacing[0.5]};
`
export const Icon = styled(InfoIcon)`
  width: 12px;
  padding-top: 0px;

  ${media.md(css`
    width: 14px;
    padding-top: 2px;
  `)}
`

export const TimeWrapper = styled.div<{ isSpaBooking?: boolean }>(
  ({ isSpaBooking }) => ({
    minWidth: isSpaBooking && 46,

    [mediaObj.md]: {
      minWidth: isSpaBooking && theme.spacing[3.5],
    },
  })
)
