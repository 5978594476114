import { nationalities } from './nationalities'
const defaultPreferredCountries = ['US', 'GB', 'IS']

const collator = new Intl.Collator('en', {
  numeric: true,
  sensitivity: 'base',
})

export const sortedNationalities = (
  preferredCountries = defaultPreferredCountries
) => {
  return nationalities.sort((a, b) => {
    const indexA = preferredCountries.indexOf(a.value)
    const indexB = preferredCountries.indexOf(b.value)

    // If both are in preferredCountries, maintain their order in preferredCountries
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }

    // If one of them is in preferredCountries, that one comes first
    if (indexA !== -1) return -1
    if (indexB !== -1) return 1

    // If neither are in preferredCountries, sort alphabetically by label
    return collator.compare(a.label, b.label)
  })
}
