import { isValidElement } from 'react'

import { colors } from '../../constants/colors'
import { Amount } from '../../elements/Amount'
import { Type } from '../../elements/Typography/Typography'
import * as styles from './styles'

interface CartCardProps {
  title: string | React.ReactNode
  description: string | React.ReactNode
  description2?: string
  disclaimer?: string
  time?: string | React.ReactNode
  price?: any
  discountedPrice?: any
  canDelete?: boolean
  onDelete?: () => void
  deleteLabel?: string
  edit?: (item: CartCardProps) => void
  editLabel?: string
  editing?: boolean
  isSpaBooking?: boolean
  displayFractionalPrices?: boolean
}

export const CartCard: React.FC<CartCardProps> = item => {
  const {
    title,
    description,
    description2,
    disclaimer = undefined,
    time,
    price = undefined,
    discountedPrice = undefined,
    canDelete = false,
    onDelete,
    deleteLabel = 'Remove',
    edit = undefined,
    editLabel = 'Edit',
    editing = false,
    isSpaBooking,
    displayFractionalPrices,
  } = item
  return (
    <styles.Card>
      {(time || isSpaBooking) && (
        <styles.TimeWrapper isSpaBooking={isSpaBooking}>
          <Type right={1} preset="labelSmall" weight="normal">
            {time}
          </Type>
        </styles.TimeWrapper>
      )}

      <styles.Content>
        <Type preset="labelSmall" weight="bold">
          {title}
        </Type>
        <Type size={{ xs: 12, md: 12 }}>{description}</Type>
        {description2 && <Type size={{ xs: 12, md: 12 }}>{description2}</Type>}
        {disclaimer && (
          <styles.Disclaimer>
            <styles.Icon color={colors.deepBlue} />
            <Type left={{ xs: 0.5 }} size={{ xs: 12 }}>
              {disclaimer}
            </Type>
          </styles.Disclaimer>
        )}
      </styles.Content>
      <styles.Price style={{ marginLeft: !!discountedPrice && 8 }}>
        <Type
          preset="textSmall"
          textAlign="right"
          weight="bold"
          textDecoration={discountedPrice ? 'line-through' : ''}
          opacity={discountedPrice ? 0.7 : 1}
          inline
          right={0.5}
        >
          {isValidElement(price) ? (
            price
          ) : (
            <Amount
              value={price}
              displayFractionalPrices={displayFractionalPrices}
            />
          )}
        </Type>
        {!!discountedPrice && (
          <Type preset="textSmall" textAlign="right" weight="bold" inline>
            <Amount
              value={discountedPrice}
              displayFractionalPrices={displayFractionalPrices}
            />
          </Type>
        )}
        {editing && (
          <styles.ControlWrapper>
            {onDelete && (
              <styles.ControlButton
                onClick={onDelete}
                disabled={!canDelete}
                type="button"
              >
                <Type size={{ xs: 12 }}>{deleteLabel}</Type>
              </styles.ControlButton>
            )}
            {edit && (
              <styles.ControlButton onClick={() => edit(item)} type="button">
                <Type size={{ xs: 12 }}>{editLabel}</Type>
              </styles.ControlButton>
            )}
          </styles.ControlWrapper>
        )}
      </styles.Price>
    </styles.Card>
  )
}
